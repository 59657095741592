@mixin btn-custom-variant($background, $color, $border, $bg-hover, $hover, $border-hover, $bg-active, $active, $border-active, $bg-disabled, $disabled, $border-disabled, $shadow, $shadow-focus-color) {
    box-shadow: $shadow;
    border-radius: $border-radius;
    padding: 6px 16px;
    font-weight: 500;

    background-color: $background !important;
    color: $color !important;
    border-color: $border !important;
    border: 1px solid $border !important;

    &.btn-sm {
        padding: 4px 10px !important;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    &.btn-lg {
        padding: 8px 16px !important;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }

    svg {
        position: relative;
        top: -1px;
        
        path {
            stroke: $color;
        }
    }

    &:hover {
        background-color: $bg-hover !important;
        color: $hover !important;
        border-color: $border-hover !important;
        
        svg {
            path {
                stroke: $hover;
            }
        }
    }

    &:focus, &:focus-visible {
        box-shadow: 0 0 0 0.25rem $shadow-focus-color !important;

        background-color: $bg-hover !important;
        color: $hover !important;
        border-color: $border-hover !important;
    }

    &:active, &.active {
        background-color: $bg-active !important;
        color: $active !important;
        border-color: $border-active !important;
        
        svg {
            path {
                stroke: $active;
            }
        }
    }

    &:disabled {
        pointer-events: auto !important;
        cursor: not-allowed !important;

        background-color: $bg-disabled !important;
        color: $disabled !important;
        border-color: $border-disabled !important;
        
        svg {
            path {
                stroke: $disabled;
            }
        }
    }
}

@mixin btn-icon-variant($background, $color, $border, $bg-hover, $hover, $border-hover, $bg-active, $active, $border-active, $bg-disabled, $disabled, $border-disabled, $shadow, $shadow-focus-color) {
    display: inline-flex;
    align-items: center;
  
    box-shadow: $shadow;
    border-radius: $border-radius;
    padding: 0px !important;
  
    background-color: $background;
    color: $color;
    border-color: $border;
  
    svg {
      width: 15px !important;
      height: 17px !important;
      
      path {
        stroke: $color;
      }
    }
  
    &.btn-sm {
      svg {
        width: 10px !important;
        height: 10px !important;
      }
    }
  
    &.btn-lg {
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  
    &:hover {
      background-color: $bg-hover !important;
      color: $hover !important;
      border-color: $border-hover !important;
      
      svg {
        path {
          stroke: $hover;
        }
      }
    }
  
    &:focus, &:focus-visible {
      box-shadow: 0 0 0 0.25rem $shadow-focus-color !important;
  
      background-color: $bg-hover !important;
      color: $color !important;
      border-color: $border-hover !important;
      
      svg {
        path {
          stroke: $color;
        }
      }
    }
    
    &:active {
      background-color: $bg-active !important;
      color: $active !important;
      border-color: $border-active !important;
      
      svg {
        path {
          stroke: $active;
        }
      }
    }
  
    &:disabled {
      pointer-events: auto !important;
      cursor: not-allowed !important;
  
      background-color: $bg-disabled !important;
      color: $disabled !important;
      border-color: $border-disabled !important;
      
      svg {
        path {
          stroke: $disabled;
        }
      }
    }
}

@mixin tag-custom-variant($background, $color, $border) {
    border-radius: 4px !important;
    padding: 2px 8px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
  
    background-color: $background !important;
    color: $color !important;
    border: 1px solid $border !important;
}





/* COLORS */
$my-gray-50:  #F8FAFC;
$my-gray-100: #F0F0F1; // Light
$my-gray-200: #E9E9EA;
$my-gray-300: #DEDEE1;
$my-gray-400: #C3C4C9;
$my-gray-500: #A3A5AD;
$my-gray-600: #7F818D; // Secondary
$my-gray-700: #6E707E;
$my-gray-800: #505363;
$my-gray-900: #181C32; // Dark

$my-blue-50: #D1DEFF;
$my-blue-100: #B2C9FF;
$my-blue-200: #8BADFF;
$my-blue-300: #6592FF;
$my-blue-500: #185CFF;
$my-blue-600: #144DD4;
$my-blue-700: #103DAA;
$my-blue-800: #0C2E80;
$my-blue-900: #081F55;
$my-blue-focus1: #F6F9FF;
$my-blue-focus2: #BCC9EA;
$my-blue-focus3: #F0F5FF;

$my-yellow-400: #D59151;
$my-yellow-500: #CD7B2E;
$my-yellow-focus1: #FFFAF5;
$my-yellow-focus2: #FFF6ED;

$my-green-50: #D9E9E2;
$my-green-400: #62A584;
$my-green-500: #43936C;
$my-green-focus1: #F5FFFA;
$my-green-focus2: #EDFFF6;

$my-red-focus2: #FFF1F0;
$my-red-100: #EEBDBA;
$my-red-500: #CB3A31;
$my-red-focus1: #FFF5FA;
$my-red-focus2: #FFF1F0;

$my-purple-400: #BD1FF5;
$my-purple-focus1: #fbefff;

$my-shadow1: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
$my-shadow2: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
/* END COLORS */



body {
    &[data-page='gantt-chart'] {
        .gantt-popover-container {
            &[role=tooltip] {
                z-index: 1049 !important;
            }
        }
    
    
    
    
    
        /* BUTTON */
        $custom-prefix: 'custom';
    
        $custom-primary-bg-color: $my-blue-500;
        $custom-primary-color: $white;
        $custom-primary-border: $my-blue-500;
        $custom-primary-bg-hover: $my-blue-600;
        $custom-primary-hover: $white;
        $custom-primary-border-hover: $my-blue-500;
        $custom-primary-bg-active: $my-blue-800;
        $custom-primary-active: $white;
        $custom-primary-border-active: $my-blue-800;
        $custom-primary-bg-disabled: $my-gray-200;
        $custom-primary-disabled: $my-gray-500;
        $custom-primary-border-disabled: $my-gray-300;
        $custom-primary-shadow: $my-shadow1;
        $custom-primary-shadow-focus-color: $my-blue-focus2;
        .btn-#{$custom-prefix}-primary {
            @include btn-custom-variant(
                $custom-primary-bg-color,
                $custom-primary-color,
                $custom-primary-border,
                $custom-primary-bg-hover,
                $custom-primary-hover,
                $custom-primary-border-hover,
                $custom-primary-bg-active,
                $custom-primary-active,
                $custom-primary-border-active,
                $custom-primary-bg-disabled,
                $custom-primary-disabled,
                $custom-primary-border-disabled,
                $custom-primary-shadow,
                $custom-primary-shadow-focus-color,
            );
        }

        $custom-outlined-bg-color: $white;
        $custom-outlined-color: $my-gray-800;
        $custom-outlined-border: $my-gray-300;
        $custom-outlined-bg-hover: $my-gray-50;
        $custom-outlined-hover: $my-gray-800;
        $custom-outlined-border-hover: $my-blue-500;
        $custom-outlined-bg-active: $white;
        $custom-outlined-active: $my-gray-800;
        $custom-outlined-border-active: $my-blue-500;
        $custom-outlined-bg-disabled: $my-gray-200;
        $custom-outlined-disabled: $my-gray-500;
        $custom-outlined-border-disabled: $my-gray-300;
        $custom-outlined-shadow: $my-shadow1;
        $custom-outlined-shadow-focus-color: $my-blue-focus2;
        .btn-#{$custom-prefix}-outlined {
            @include btn-custom-variant(
                $custom-outlined-bg-color,
                $custom-outlined-color,
                $custom-outlined-border,
                $custom-outlined-bg-hover,
                $custom-outlined-hover,
                $custom-outlined-border-hover,
                $custom-outlined-bg-active,
                $custom-outlined-active,
                $custom-outlined-border-active,
                $custom-outlined-bg-disabled,
                $custom-outlined-disabled,
                $custom-outlined-border-disabled,
                $custom-outlined-shadow,
                $custom-outlined-shadow-focus-color,
            );
        }
    
        $custom-outlined-warning-bg-color: $my-red-focus2;
        $custom-outlined-warning-color: $my-yellow-500;
        $custom-outlined-warning-border: $my-red-100;
        $custom-outlined-warning-bg-hover: $my-red-focus2;
        $custom-outlined-warning-hover: $my-yellow-500;
        $custom-outlined-warning-border-hover: $my-blue-500;
        $custom-outlined-warning-bg-active: $my-red-focus2;
        $custom-outlined-warning-active: $my-yellow-500;
        $custom-outlined-warning-border-active: $my-red-100;
        $custom-outlined-warning-bg-disabled: $my-gray-200;
        $custom-outlined-warning-disabled: $my-gray-500;
        $custom-outlined-warning-border-disabled: $my-gray-300;
        $custom-outlined-warning-shadow: $my-shadow1;
        $custom-outlined-warning-shadow-focus-color: $my-blue-focus2;
        .btn-#{$custom-prefix}-outlined-warning {
            @include btn-custom-variant(
                $custom-outlined-warning-bg-color,
                $custom-outlined-warning-color,
                $custom-outlined-warning-border,
                $custom-outlined-warning-bg-hover,
                $custom-outlined-warning-hover,
                $custom-outlined-warning-border-hover,
                $custom-outlined-warning-bg-active,
                $custom-outlined-warning-active,
                $custom-outlined-warning-border-active,
                $custom-outlined-warning-bg-disabled,
                $custom-outlined-warning-disabled,
                $custom-outlined-warning-border-disabled,
                $custom-outlined-warning-shadow,
                $custom-outlined-warning-shadow-focus-color,
            );
        }
    
        $custom-secondary-light-bg-color: $my-gray-100;
        $custom-secondary-light-color: $my-gray-500;
        $custom-secondary-light-border: $my-gray-100;
        $custom-secondary-light-bg-hover: $my-gray-50;
        $custom-secondary-light-hover: $my-gray-600;
        $custom-secondary-light-border-hover: $my-gray-50;
        $custom-secondary-light-bg-active: $my-gray-50;
        $custom-secondary-light-active: $my-gray-800;
        $custom-secondary-light-border-active: $my-blue-500;
        $custom-secondary-light-bg-disabled: $my-gray-200;
        $custom-secondary-light-disabled: $my-gray-500;
        $custom-secondary-light-border-disabled: $my-gray-300;
        $custom-secondary-light-shadow: unset;
        $custom-secondary-light-shadow-focus-color: $my-blue-focus2;
        .btn-#{$custom-prefix}-secondary-light {
            @include btn-custom-variant(
                $custom-secondary-light-bg-color,
                $custom-secondary-light-color,
                $custom-secondary-light-border,
                $custom-secondary-light-bg-hover,
                $custom-secondary-light-hover,
                $custom-secondary-light-border-hover,
                $custom-secondary-light-bg-active,
                $custom-secondary-light-active,
                $custom-secondary-light-border-active,
                $custom-secondary-light-bg-disabled,
                $custom-secondary-light-disabled,
                $custom-secondary-light-border-disabled,
                $custom-secondary-light-shadow,
                $custom-secondary-light-shadow-focus-color,
            );
        }
    
        $custom-dark-bg-color: $my-gray-900;
        $custom-dark-color: $white;
        $custom-dark-border: $my-gray-500;
        $custom-dark-bg-hover: $my-gray-600;
        $custom-dark-hover: $white;
        $custom-dark-border-hover: $my-gray-500;
        $custom-dark-bg-active: $my-gray-800;
        $custom-dark-active: $white;
        $custom-dark-border-active: $my-gray-800;
        $custom-dark-bg-disabled: $my-gray-200;
        $custom-dark-disabled: $my-gray-500;
        $custom-dark-border-disabled: $my-gray-300;
        $custom-dark-shadow: $my-shadow1;
        $custom-dark-shadow-focus-color: $my-blue-focus2;
        .btn-#{$custom-prefix}-dark {
            @include btn-custom-variant(
                $custom-dark-bg-color,
                $custom-dark-color,
                $custom-dark-border,
                $custom-dark-bg-hover,
                $custom-dark-hover,
                $custom-dark-border-hover,
                $custom-dark-bg-active,
                $custom-dark-active,
                $custom-dark-border-active,
                $custom-dark-bg-disabled,
                $custom-dark-disabled,
                $custom-dark-border-disabled,
                $custom-dark-shadow,
                $custom-dark-shadow-focus-color,
            );
        }
    
        $custom-none-primary-bg-color: $transparent;
        $custom-none-primary-color: $my-gray-600;
        $custom-none-primary-border: $transparent;
        $custom-none-primary-bg-hover: $transparent;
        $custom-none-primary-hover: $my-gray-700;
        $custom-none-primary-border-hover: $transparent;
        $custom-none-primary-bg-active: $transparent;
        $custom-none-primary-active: $my-gray-800;
        $custom-none-primary-border-active: $transparent;
        $custom-none-primary-bg-disabled: $transparent;
        $custom-none-primary-disabled: $my-gray-500;
        $custom-none-primary-border-disabled: $transparent;
        $custom-none-primary-shadow: unset;
        $custom-none-primary-shadow-focus-color: unset;
        .btn-#{$custom-prefix}-icon-secondary {
            @include btn-icon-variant(
                $custom-none-primary-bg-color,
                $custom-none-primary-color,
                $custom-none-primary-border,
                $custom-none-primary-bg-hover,
                $custom-none-primary-hover,
                $custom-none-primary-border-hover,
                $custom-none-primary-bg-active,
                $custom-none-primary-active,
                $custom-none-primary-border-active,
                $custom-none-primary-bg-disabled,
                $custom-none-primary-disabled,
                $custom-none-primary-border-disabled,
                $custom-none-primary-shadow,
                $custom-none-primary-shadow-focus-color,
            );
        }
        /* END BUTTON */





        /* STATUS TAGS */
        $custom-tags-defalut-bg-color: $my-gray-50;
        $custom-tags-defalut-color: $my-gray-600;
        $custom-tags-defalut-border: $transparent;
        .badge.bg-#{$custom-prefix}-default,
        .badge.bg-#{$custom-prefix}-unallocated,
        .badge.bg-#{$custom-prefix}-assigned,
        .badge.bg-#{$custom-prefix}-dispatched,
        .badge.bg-#{$custom-prefix}-acknowledged {
            @include tag-custom-variant(
                $custom-tags-defalut-bg-color,
                $custom-tags-defalut-color,
                $custom-tags-defalut-border,
            );
        }

        $custom-tags-defalut-bg-color: $my-blue-focus1;
        $custom-tags-defalut-color: $my-blue-500;
        $custom-tags-defalut-border: $transparent;
        .badge.bg-#{$custom-prefix}-started {
            @include tag-custom-variant(
                $custom-tags-defalut-bg-color,
                $custom-tags-defalut-color,
                $custom-tags-defalut-border,
            );
        }

        $custom-tags-defalut-bg-color: $my-purple-focus1;
        $custom-tags-defalut-color: $my-purple-400;
        $custom-tags-defalut-border: $transparent;
        .badge.bg-#{$custom-prefix}-inprogress {
            @include tag-custom-variant(
                $custom-tags-defalut-bg-color,
                $custom-tags-defalut-color,
                $custom-tags-defalut-border,
            );
        }

        $custom-tags-defalut-bg-color: $my-green-focus1;
        $custom-tags-defalut-color: $my-green-500;
        $custom-tags-defalut-border: $transparent;
        .badge.bg-#{$custom-prefix}-completed {
            @include tag-custom-variant(
                $custom-tags-defalut-bg-color,
                $custom-tags-defalut-color,
                $custom-tags-defalut-border,
            );
        }

        $custom-tags-defalut-bg-color: $my-red-focus1;
        $custom-tags-defalut-color: $my-red-500;
        $custom-tags-defalut-border: $transparent;
        .badge.bg-#{$custom-prefix}-cancelled,
        .badge.bg-#{$custom-prefix}-failed,
        .badge.bg-#{$custom-prefix}-rejected {
            @include tag-custom-variant(
                $custom-tags-defalut-bg-color,
                $custom-tags-defalut-color,
                $custom-tags-defalut-border,
            );
        }
        /* END STATUS TAGS */
    
    
        
        
        
        /* STATUS TEXT */
        .unallocated-txt, .draft-txt {
            color: $my-gray-600;
        }

        .assigned-txt {
            color: $my-gray-600;
        }

        .dispatched-txt, .acknowledged-txt {
            color: $my-gray-600;
        }

        .started-txt {
            color: $my-blue-500;
        }

        .inprogress-txt {
            color: $my-purple-400;
        }

        .completed-txt {
            color: $my-green-500;
        }

        .cancelled-txt, .failed-txt {
            color: $my-red-500;
        }

        .unallocated-tag, .draft-tag {
            background-color: $my-gray-100 !important;
            color: $my-gray-600;
        }
        .assigned-tag {
            background-color: $my-gray-100 !important;
            color: $my-gray-600;
        }
        .dispatched-tag, .acknowledged-tag {
            background-color: $my-gray-100 !important;
            color: $my-gray-600;
        }
        .started-tag {
            background-color: $my-blue-focus3 !important;
            color: $my-blue-500;
        }
        .inprogress-tag {
            background-color: $my-purple-focus1 !important;
            color: $my-purple-400;
        }
        .completed-tag {
            background-color: $my-green-focus2 !important;
            color: $my-green-500;
        }
        .cancelled-tag, .failed-tag {
            background-color: $my-red-focus2 !important;
            color: $my-red-500;
        }
        /* END STATUS TEXT */





        /* LABEL */
        .form-label {
            font-weight: 600;
            font-size: 11px;
            line-height: 16px;
            color: $my-gray-800 !important;
        }
        /* END LABEL */





        /* SELECT */
        .ant-select {
            padding: 0px !important;
            box-shadow: unset !important;
          
            .ant-select-selector {
              background: $transparent !important;
              border: 1px solid $transparent !important;
              box-shadow: unset !important;
          
              .ant-select-selection-search {
                left: 0px;
              }
          
              .ant-select-selection-placeholder {
                color: $my-gray-500 !important;
              }
          
              .ant-select-selection-item {
                color: $my-gray-700 !important;
              }
          
              .ant-tag {
                &.custom-primary {
                    background-color: $my-blue-focus3 !important;
                    color: $my-blue-500 !important;

                    svg {
                        path {
                            fill: $my-blue-500 !important;
                        }

                        &:hover {
                            path {
                                fill: $my-gray-800 !important;
                            }
                        }
                    }
                }

                &.unallocated, &.draft {
                    background-color: $my-gray-100 !important;
                    color: $my-gray-600;
                }
    
                &.assigned {
                    background-color: $my-gray-100 !important;
                    color: $my-gray-600;
                }
    
                &.dispatched, &.acknowledged {
                    background-color: $my-gray-100 !important;
                    color: $my-gray-600;
                }
    
                &.started {
                    background-color: $my-blue-focus3 !important;
                    color: $my-blue-500;
                }
    
                &.inprogress {
                    background-color: $my-purple-focus1 !important;
                    color: $my-purple-400;
                }
    
                &.completed {
                    background-color: $my-green-focus2 !important;
                    color: $my-green-500;
                }
    
                &.cancelled, &.failed {
                    background-color: $my-red-focus2 !important;
                    color: $my-red-500;
                }
              }

              .ant-select-selection-overflow-item {
                .ant-select-selection-item {
                  background-color: $my-blue-50 !important;
          
                  .ant-select-selection-item-content {
                    color: $my-blue-500 !important;
                    font-weight: 500;
                  }
          
                  .ant-select-selection-item-remove {
                    svg {
                      path {
                        fill: $my-blue-500 !important;
                      }
                    }
          
                    &:hover {
                      svg {
                        path {
                          fill: $my-blue-800 !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          
            .ant-select-arrow {
              svg {
                path {
                  fill: $my-gray-800 !important;
                }
              }
            }
          
            .ant-select-clear {
              svg {
                path {
                  fill: $my-gray-800 !important;
                }
              }
            }
          
          
            &:hover {
              .ant-select-selector {
                border: 1px solid $transparent !important;
          
                .ant-select-selection-placeholder {
                  color: $my-gray-600 !important;
                }
              }
            }
            &.ant-select-focused {
              .ant-select-selector {
                box-shadow: 0 0 0 0.25rem $my-blue-focus2 !important;
                border: 1px solid $my-blue-500 !important;
          
                .ant-select-selection-placeholder {
                  color: $my-gray-700 !important;
                }
              }
            }
          
            &.ant-select-disabled {
              .ant-select-selector {
                background: $my-gray-200 !important;
                border: 1px solid $my-gray-300 !important;
                box-shadow: unset !important;
          
                .ant-select-selection-placeholder {
                  color: $my-gray-500 !important;
                }
          
                .ant-select-selection-item {
                  color: $my-gray-500 !important;
                }
          
                .ant-select-selection-overflow-item {
                  .ant-select-selection-item {
                    background-color: $my-gray-50 !important;
            
                    .ant-select-selection-item-content {
                      color: $my-gray-500 !important;
                    }
                  }
                }
              }
          
              .ant-select-arrow {
                cursor: not-allowed !important;
          
                svg {
                  path {
                    fill: $my-gray-500 !important;
                  }
                }
              }
            
              .ant-select-clear {
                cursor: not-allowed !important;
          
                svg {
                  path {
                    fill: $my-gray-500 !important;
                  }
                }
              }
          
              &:hover {
                border: 1px solid $my-gray-300 !important;
          
                .ant-select-selector {
                  border: 1px solid $my-gray-300 !important;
                }
              }
              
              +.form-text {
                color: $my-gray-500 !important;
              }
            }
        }
        
        .input-group:not(.ant-select-auto-complete) {
            .ant-select.form-control {
              padding-right: 0px !important;
          
              .ant-select-arrow {
                right: 37px;
              }
          
              .ant-select-clear {
                right: 37px;
              }
          
              &.is-invalid{
                +.input-group-text {
                  position: absolute !important;
                  right: 0px !important;
                }
              }
              &.is-valid{
                +.input-group-text {
                  position: absolute !important;
                  right: 0px !important;
                }
              }
            }
          
            .ant-select.form-control + .input-group-text {
              position: absolute !important;
              right: 0px !important;
          
              .btn {
                top: -1px;
              }
            }
          
            .input-group-text {
              &:first-child {
                +.ant-select.form-control, ~.ant-select.form-control {
                  padding-left: 0px !important;
                  .ant-select-selection-search, .ant-select-selection-item, .ant-select-selection-placeholder {
                    padding-left: 35px !important;
                  }
                }
          
                &.p-search {
                  +.ant-select.form-control, ~.ant-select.form-control {
                    padding-left: 0px !important;
                    .ant-select-selection-search, .ant-select-selection-item, .ant-select-selection-placeholder {
                      padding-left: 35px !important;
                    }
                  }
                }
              }
              
              &:last-child {
                +.ant-select.form-control, ~.ant-select.form-control {
                  padding-left: 0px !important;
                  .ant-select-selection-search, .ant-select-selection-item, .ant-select-selection-placeholder {
                    padding-left: 60px !important;
                  }
                }
              }
            }
          
            &:has(> .ant-select.ant-select-disabled){
              color: $my-gray-500;
              cursor: not-allowed;
          
              a {
                cursor: not-allowed;
              }
          
              svg {
                position: relative;
                top: -1px;
          
                path {
                  stroke: $my-gray-500;
                }
              }
              
              +.form-text {
                color: $my-gray-500 !important;
              }
            }
        }
        
        .ant-select-dropdown {
            z-index: 1070;
            padding: 0px;
          
            .custom-select-search {
              padding: 10px;
          
              .input-group-text {
                &:first-child {
                  +.form-control, ~.form-control {
                    padding-left: 33px !important;
                  }
                }
              }
            }
          
            .custom-select-menu {
              padding: 5px 10px;
          
              .ant-select-item-option {
                background-color: $white !important;
                padding: 0px;
                min-height: 35px;
                
                .ant-select-item-option-content {
                  color: $my-gray-700 !important;
                  border: 1px solid $transparent !important;
                  border-radius: 0px !important;
                  padding: 4px 5px !important;
          
                  .btn {
                    display: none;
                  }
                }
          
                .ant-select-item-option-state {
                  padding-right: 15px;
                }
          
                &-selected {
                  .ant-select-item-option-content {
                    color: $my-blue-500 !important;
                    background-color: $my-blue-focus1;
                    // font-weight: 400px !important;
          
                    .btn {
                      display: block;
                    }
                  }
                  
                  .ant-select-item-option-state {
                    background-color: $my-blue-focus1;
                  }
                }
                &-active {
                  .ant-select-item-option-content {
                    color: $my-gray-800 !important;
                    background-color: $my-blue-focus1;
                    
                    .btn {
                      display: block;
                    }
                  }
          
                  .ant-select-item-option-state {
                    background-color: $my-blue-focus1;
                  }
                }
                &-disabled {
                  .ant-select-item-option-content {
                    color: $my-gray-500 !important;
                  }
                }
              }
            }
            
            .custom-select-footer {
              padding: 5px 15px 10px 15px;
          
              button {
                padding: 0px;
              }
            }
        }
        /* END SELECT */





        /* GLOBAL STYLE */
        .v-divider {
            position: relative;
            width: 1px;
            height: 32px;
            background-color: $my-gray-300;
    
            &-52 {
                height: 52px;
            }
    
            &-full {
                height: 100%;
            }
        }
        /* END GLOBAL STYLE */





        /* RADIO */
        .form-check {
            input[type="radio"]{
                &.form-check-input {
                    width: 16px;
                    height: 16px;
                    margin-top: 2px;
                    margin-left: -26px;
                }
            }
        
            .form-check-label {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: $my-gray-600;
            }

            &.sm {
                .form-check-label {
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;
                    color: $my-gray-800;
                }
            }
        }
        /* END RADIO */
    
    
    
    
    
        /* DROPDOWN */
        .dropdown {
            .dropdown-header {
                font-size: 11px;
                line-height: 16px;
                font-weight: 600;
                color: $my-gray-800;
            }
    
            .dropdown-item.active, .dropdown-item:active {
                background-color: var(--bs-dropdown-link-hover-bg);
            }
        }
        /* END DROPDOWN */
    
    
    
    
    
        /* DATE PICKER */
        .custom-date-picker-wrapper {
            background-color: $custom-outlined-bg-color !important;
            border-color: $custom-outlined-border !important;
            border: 1px solid $custom-outlined-border !important;
            border-radius: $border-radius !important;
            padding: 2px 5px 2px;
            cursor: pointer;

            &:hover {
                border: 1px solid $custom-outlined-border-hover !important;
                background-color: $custom-outlined-bg-hover !important;
            }

            .custom-date-picker {
                position: relative;
                padding: 0px;
                transition: unset;
        
                border-radius: $border-radius;
                font-weight: 500;
        
                color: $custom-outlined-color !important;
                // background-color: $custom-outlined-bg-color !important;
                // border-color: $custom-outlined-border !important;
                // border: 1px solid $custom-outlined-border !important;

                
                background-color: transparent !important;
                border-color: transparent !important;
                border: 1px solid transparent !important;
                
                // &:hover {
                //     border: 1px solid $custom-outlined-border-hover !important;
                //     background-color: $custom-outlined-bg-hover !important;
                // }

                &:focus, &.ant-picker-focused {
                    box-shadow: unset !important;
                }
        
                .ant-picker-input {
                    cursor: pointer;

                    input {
                        padding-left: 10px;
                        text-align: center;
                    }
        
                    .ant-picker-suffix {
                        position: absolute;
                        top: 3px;
                        left: 0px;
                        padding-left: 10px;
        
                        svg {
                            path {
                                stroke: $custom-outlined-color;
                            }
                        }
                    }
                }
            }

            &.disabled {
                pointer-events: auto !important;
                cursor: not-allowed !important;
        
                background-color: $my-gray-200 !important;
                color: $my-gray-500 !important;
                border-color: $my-gray-300 !important;
                
                svg {
                    path {
                        stroke: $my-gray-500;
                    }
                }
            }
        }
        /* END DATE PICKER */
    
    
    
    
    
        /* SEARCH */
        .search-input {
            position: relative;

            svg {
                position: absolute;
                z-index: 1;
                top: 8px;
                left: 10px;
            }

            input {
                min-height: 31px;
                padding-top: 0px;
                padding-bottom: 0px;
                padding-left: 35px;
                font-size: 14px;
                line-height: 14px;
            }
        }
        /* END SEARCH */





        /* TAGS PICKER */
        .custom-rsuite-tags-picker {
            .rs-picker-input {
                .rs-picker-toggle {
                    min-height: 0px !important;
                    padding-bottom: 0px !important;
                    padding-top: 7px !important;

                    .rs-stack {
                        .rs-stack-item {
                            svg {
                                top: 7px !important;
                            }
                        }
                    }
                }
            }
        }
        /* END TAGS PICKER */
        




        /* TAGS PICKER */
        .custom-gantt-input-picker {
            .rs-picker-input {
                .rs-picker-toggle {
                    height: 32px !important;
                    min-height: 0px !important;
                    padding-bottom: 0px !important;
                    padding-top: 4px !important;

                    .rs-stack {
                        .rs-stack-item {
                            svg {
                                top: 7px !important;
                            }
                        }
                    }
                }
            }
        }
        /* END TAGS PICKER */





        /* DRIVER FIELD */
        .custom-head-driver-row {
            cursor: pointer;

            &:hover {
                font-weight: bold;

                svg {
                    path {
                        stroke: $my-gray-800;
                    }
                }
            }
        }
        /* END DRIVER FIELD */





        /* CAROUSEL */
        .custom-carousel {
            &.weekly, &.monthly {
                @media (min-width: 992px) {
                    width: 60%;
                }
                @media (min-width: 1200px) {
                    width: 50%;
                 }
            }
            &.multiple-trips, &.unset-time {
                @media (min-width: 992px) {
                    width: 25%;
                }
            }
            &.job-details {
                @media (min-width: 992px) {
                    width: 55%;
                }
            }

            .rec-arrow-left {
                width: unset !important;
                height: unset !important;
                min-width: unset !important;
                line-height: unset !important;
                box-shadow: unset !important;
                background-color: transparent !important;
            
                &[disabled] {
                  display: none !important;
                }
            }
        
            .rec-arrow-right {
                width: unset !important;
                height: unset !important;
                min-width: unset !important;
                line-height: unset !important;
                box-shadow: unset !important;
                background-color: transparent !important;
            
                &[disabled] {
                  display: none !important;
                }
            }

            .rec-slider-container {
                margin: 0px;
            }

            .rec-swipable {
                width: 99.5%;
                border: 1px solid $my-gray-300;
                border-radius: 12px;
                
                .ant-spin-nested-loading {
                    overflow: hidden;
                }

                > div {
                    .rec-item-wrapper {
                        justify-content: left;

                        .custom-carousel-item {
                            position: relative;
                            padding: 10px 20px;
                            width: 100%;
                            // cursor: pointer;
    
                            .count {
                                font-size: 16px;
                                line-height: 24px;
                                font-weight: 500;

                                &.unallocated, &.draft {
                                    color: $my-gray-400;
                                }
                    
                                &.assigned {
                                    color: $my-gray-400;
                                }
                    
                                &.dispatched, &.acknowledged {
                                    color: $my-gray-600;
                                }
                    
                                &.started {
                                    color: $my-blue-500;
                                }
                    
                                &.inprogress {
                                    color: $my-purple-400;
                                }
                    
                                &.completed {
                                    color: $my-green-500;
                                }
                    
                                &.cancelled, &.failed {
                                    color: $my-red-500;
                                }
                            }
    
                            .text {
                                text-wrap: nowrap;
                                font-size: 14px;
                                line-height: 20px;
                            }

                            
                            // &:hover {
                            //     .text {
                            //         font-weight: bold;
                            //         transition: 0.3s;
                            //     }
                            // }

                            &.active {
                                .text {
                                    font-weight: bold;
                                }
                            }
                        }

                        .v-divider {
                            width: 1px;
                        }
                    }
                }
            }

            &.multiple-trips, &.job-details, &.unset-time {
                .count {
                    font-weight: 500 !important;
                    font-size: 14px !important;
                    line-height: 20px !important;
                }
                
                .text {
                    font-weight: 400 !important;
                    font-size: 14px !important;
                    line-height: 20px !important;
                }
            }
        }
        /* END CAROUSEL */





        /* TABLE */
        .gantt-chart-weekly-monthly-table {
            border-spacing: 0;
            border-collapse: separate;
            border-radius: 6px;
            border: 1px solid #DEDEE1;
            overflow: hidden;

            tr {
                border-bottom: 1px solid #DEDEE1;

                &:last-child {
                    border-bottom: 0px;

                    td {
                        border-bottom: 0px;
                    }
                }

                th {
                    position: relative !important;
                    background-color: transparent;
                    height: 44px;
                    min-width: 100px;
                    border-right: 1px solid #DEDEE1;
                    padding: 14px 16px !important;
                    color: #6E707E !important;
                    font-weight: 400 !important;
                    font-size: 12px;
                    line-height: 18px;
                    
                    &:last-child {
                        border-right: 0px;
                    }

                    .custom-cell-sort {
                        position: relative !important;
                        right: 0px;
                        top: 0px;
                        width: 13px;
                        height: 22px;
                        text-align: center;
                        min-width: 13px;

                        &.custom-sort {
                            position: absolute !important;
                            right: 10px;
                            top: 12px;
                        }

                        .sort-up {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 12px;
                            height: 12px;
                            overflow: hidden;
                            cursor: pointer;

                            &:hover, &.active {
                                path {
                                    fill: #7F818D;
                                }
                            }
                        }
                        .sort-down {
                            position: absolute;
                            bottom: 0px;
                            left: 0px;
                            width: 12px;
                            height: 12px;
                            overflow: hidden;
                            cursor: pointer;

                            &:hover, &.active {
                                path {
                                    fill: #7F818D;
                                }
                            }
                        }
                    }
                }

                td {
                    background-color: transparent !important;
                    height: 44px !important;
                    border-right: 1px solid #DEDEE1;
                    padding: 14px 16px !important;
                    color: #6E707E;
                    font-weight: 500 !important;
                    font-size: 12px !important;
                    line-height: 18px !important;
                    
                    &:last-child {
                        border-right: 0px;
                    }

                    &.title {
                        font-weight: 600 !important;
                        font-size: 14px !important;
                        line-height: 20px !important;
                    }
                }
            }
        }

        .gantt-chart-side-panel-table {
            border-spacing: 0;
            border-collapse: separate;
            border-radius: 6px;
            border: 1px solid #DEDEE1;
            overflow: hidden;

            tr {
                border-bottom: 1px solid #DEDEE1;

                &:last-child {
                    border-bottom: 0px;

                    td {
                        border-bottom: 0px;
                    }
                }

                th {
                    position: relative !important;
                    background-color: transparent;
                    height: 44px;
                    min-width: 100px;
                    padding: 14px 16px !important;
                    color: #6E707E !important;
                    font-weight: 400 !important;
                    font-size: 12px;
                    line-height: 18px;
                    
                    &:last-child {
                        border-right: 0px;
                    }

                    .custom-cell-sort {
                        position: relative !important;
                        right: 0px;
                        top: 0px;
                        width: 13px;
                        height: 22px;
                        text-align: center;
                        min-width: 13px;

                        &.custom-sort {
                            position: absolute !important;
                            right: 10px;
                            top: 12px;
                        }

                        .sort-up {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 12px;
                            height: 12px;
                            overflow: hidden;
                            cursor: pointer;

                            &:hover, &.active {
                                path {
                                    fill: #7F818D;
                                }
                            }
                        }
                        .sort-down {
                            position: absolute;
                            bottom: 0px;
                            left: 0px;
                            width: 12px;
                            height: 12px;
                            overflow: hidden;
                            cursor: pointer;

                            &:hover, &.active {
                                path {
                                    fill: #7F818D;
                                }
                            }
                        }
                    }
                }

                td {
                    background-color: transparent !important;
                    height: 44px !important;
                    padding: 14px 16px !important;
                    color: #6E707E;
                    font-weight: 500 !important;
                    font-size: 12px !important;
                    line-height: 18px !important;
                    
                    &:last-child {
                        border-right: 0px;
                    }

                    &.title {
                        font-weight: 600 !important;
                        font-size: 14px !important;
                        line-height: 20px !important;
                    }
                }
            }
        }
        /* END TABLE */
        
        



        .new-gantt-chart-page {
            background-color: white;
        
            > div {
                margin: 0;
            }



            /* TOP section */
            .top-section {
                padding-top: 20px;
                padding-bottom: 20px;
        
                .title {
                    font-size: 20px;
                    line-height: 26px;
                    font-weight: 500;
                    color: $my-gray-900;
                }
            }
            /* END TOP section */
        
            
        
        
        
            /* FILTER section */
            .filter-section {
                padding-top: 24px;
                padding-bottom: 16px;

                .custom-filter-badge {
                    width: 16px;
                    height: 16px;
                    border-radius: 100%;
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 16px;
                    color: $white;
                    background-color: $my-blue-500 !important;
                    padding: 0px;
                }
        
                .job-info-dropdown {
                    .dropdown-menu {
                        .dropdown-header {
                            font-weight: 600;
                            font-size: 11px;
                            line-height: 16px;
                            color: $my-gray-800 !important;
                        }
        
                        .dropdown-divider {
                            border-top: 1px solid $my-gray-300;
                        }
        
                        .dropdown-item {
                            cursor: pointer;
                            color: $my-gray-600 !important;
        
                            .form-check {
                                .form-check-input {
                                    width: 16px;
                                    height: 16px;
                                    border-radius: 5px;
                                    top: 4px;
                                    position: relative;
        
                                    &:disabled {
                                        opacity: 1;
                                    }
                                }
        
                                .form-check-label {
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: $my-gray-600 !important;
                                    opacity: 1 !important;
                                }
                            }
                        }
                    }
                }
            }
            /* END FILTER section */
            
            
        
        
        
            /* STATUS section */
            .status-section {
                padding-bottom: 8px;
                height: auto;
                opacity: 1;
            }
            /* END STATUS section */
            
            
        
        
        
            /* LEGEND section */
            .legend-section {
                padding-top: 16px;
                padding-bottom: 16px;
        
                > .row {
                    padding-left: 15px;
                    padding-right: 15px;
                    
                    > div {
                        > .row {
                            gap: 6px;
        
                            > div {
                                position: relative;
                            }
                        }
                    }
                }
            }
            /* END LEGEND section */
            
            
        
        
        
            /* LIST section */
            .list-section {
                .custom-zoom-buttons {
                    background-color: $white;
                    border-left: 1px solid $my-gray-300;
                    border-top-right-radius: 6px;
                    z-index: 1;
                    position: absolute;
                    height: 31px;
                    top: 1px;
                    right: 1px;
                    overflow: hidden;
        
                    .btn {
                        padding: 5px 15px !important;
                        border-radius: 0px !important;
        
                        &:hover {
                            background-color: $my-gray-100 !important;
                        }
                    }
                }
        
                .sg-gantt {
                    border: 1px solid $my-gray-300;
                    border-radius: 6px;
                    overflow: hidden;
        
                    .sg-table {
                        .sg-table-header {
                            background-color: $white;
        
                            .sg-table-header-cell {
                                width: 100%;
                                display: grid;
        
                                font-size: 12px;
                                line-height: 18px;
                                font-weight: 400;
                                color: $my-gray-600;
                            }
                        }
        
                        .sg-table-body {
                            .sg-table-rows {
                                overflow: hidden;

                                .sg-table-row {
                                    &.actual-row {
                                        background-color: $my-blue-focus3;
                                    }
                                    
                                    &.empty-row {
                                        background-color: white;
                                        border: 1px solid white;

                                        .sg-table-body-cell {
                                            border-bottom: 1px solid white !important;
                                        }
                                    }
                                }
                            }

                            .sg-table-body-cell {
                                display: grid;
                                border-right: 0px;
                                // align-items: unset;
                                // align-items: center;
                                align-items: self-start;
                                overflow: visible;
                                background-color: transparent;
        
                                font-size: 14px;
                                line-height: 20px;
                                font-weight: 400;
                                color: $my-gray-800;
        
                                .my-custom-row {
                                    display: grid;
                                    background-color: transparent;
                                    overflow: hidden;
                                    margin-top: 20px;

                                    &.actual {
                                        margin-top: 9px;

                                        > .row {
                                            flex-direction: column;
                                        }
                                    }
                                    
                                    .card-driver-info {
                                        margin-top: 18px;
                                        
                                        > .row {
                                            margin-bottom: 4px;
        
                                            &:last-child {
                                                margin-top: 16px;
                                            }
                                        }
                                    }
        
                                    .text-driver {
                                        font-size: 14px;
                                        line-height: 20px;
                                        font-weight: 600;
                                        color: $my-gray-800;

                                        max-width: 185px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: block;
                                    }
        
                                    .text-other-vertical {
                                        font-size: 12px;
                                        line-height: 18px;
                                        font-weight: 500;
                                        color: $my-gray-600;
        
                                        // width: 185px;
                                        // white-space: nowrap;
                                        // overflow: hidden;
                                        // text-overflow: ellipsis;
                                        // display: block;

                                        &.active {
                                            color: $my-gray-800;
                                        }
                                    }
        
                                    .text-other-horisontal {
                                        font-size: 14px;
                                        line-height: 20px;
                                        font-weight: 400;
                                        color: $my-gray-800;
                                    }
        
                                    .btn {
                                        font-size: 12px;
                                        line-height: 18px;
                                        font-weight: 400;
                                    }
        
                                    .button-expand {
                                        width: 18px !important;
                                        height: 18px !important;
                                        padding: 0px !important;
                                        border-radius: 4px !important;
        
                                        svg {
                                            top: -2px;
                                        }
                                    }
        
                                    .progress {
                                        position: relative;
                                        top: -2px;
                                        display: inline-block;
                                        width: 75%;
                                        height: 4px;
                                        background-color: $my-green-50 !important;
                                        border-radius: 100px;
        
                                        .progress-bar {
                                            height: 100%;
                                            background-color: $my-green-500 !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
        
                    .sg-resize {
                        background: $my-gray-400;
                        width: 1px;
                        
                        pointer-events: none;
                        cursor: default;
                        
                        &:hover {
                            transform: none;
                        }
                    }
        
                    .sg-timeline {
                        .sg-header {
                            .column-header-cell {
                                pointer-events: none !important;
                            }
        
                            .column-header-cell-label {
                                font-size: 12px;
                                line-height: 18px;
                                font-weight: 400;
                                color: $my-gray-700;
                            }
                        }
        
                        .sg-timeline-body {
                            .sg-columns {
                                z-index: 1;
                            }

                            .sg-rows {
                                .sg-row {
                                    border-bottom: 1px solid #efefef;

                                    &.actual-row {
                                        background: $my-blue-focus3;
                                    }

                                    &.empty-row {
                                        background-color: white;
                                        border: 1px solid white;
                                    }
                                }
                            }
        
                            .sg-task {
                                overflow: hidden;
        
                                border-radius: 4px;
                                background: transparent;
                                color: black;
        
                                &.resize-enabled {
                                    &:hover {
                                        &::after, &::before {
        
                                            border-color: rgb(36 36 36 / 50%) !important;
                                        }
                                    }
                                }
        
                                .sg-task-content {
                                    padding: 2px 10px !important;
                                    width: 100% !important;
                                    overflow: hidden !important;
                                    margin-top: 3px;
                                    border-radius: 4px;
        
                                    .sg-task-button {
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        left: 0;
                                        top: 0;
                                    }
                                }
        
                                &.sg-task-selected {
                                    outline: unset;
                                    // outline: 1px solid rgba($my-blue-500, 0.5);
                                    // outline-offset: 2px;
                                }
        
                                .custom-task {
                                    width: 100%;
                                    overflow: hidden;
                                    cursor: pointer;
                                    
                                    // &.resizable {}
        
                                    // &.draggable {
                                    //     cursor: move;
                                    // }
        
                                    
                                    .badge {
                                        padding: 0px;
                                        margin-right: 5px;
        
                                        border-radius: 2px;
                                        width: 16px;
                                        height: 14px;
        
                                        font-size: 11px;
                                        line-height: 15px;
                                        font-weight: 600;
        
                                        background-color: $white !important;
                                        color: $my-gray-600;
                                    }
        
                                    .title {
                                        position: relative;
                                        font-size: 12px;
                                        line-height: 16px;
                                        font-weight: 600;
        
                                        width: 100%;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
        
                                    .desc {
                                        position: relative;
                                        font-size: 11px;
                                        line-height: 16px;
                                        font-weight: 400;
        
                                        width: 100%;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
        
                                            
                                &.unallocated, &.draft {
                                    color: black;
                                    border-bottom: 1px solid $my-gray-400;
        
                                    .sg-task-content {
                                        background: $white;
                                        border: 1px solid $my-gray-400;
                                        
                                        &::before {
                                            content: '';
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 10%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 60%, transparent 60%, transparent);
                                            background-size: 10px 10px;
                                            pointer-events: none;
                                        }
                                    }
        
                                    .custom-task {
                                        .badge {
                                            border: 1px solid $my-gray-400;
                                            color: $my-gray-600;
                                        }
        
                                        .title {
                                            span {
                                                background-color: $white;
                                            }
                                        }
            
                                        .desc {
                                            span {
                                                background-color: $white;
                                            }
                                        }
                                    }
                                }
        
                                &.assigned {
                                    color: black;
        
                                    .sg-task-content {
                                        background: $my-gray-300;
                                    }
        
                                    .custom-task {
                                        .badge {
                                            color: $my-gray-600;
                                        }
                                    }
                                }
                                
                                &.dispatched, &.acknowledged {
                                    color: white;
        
                                    .sg-task-content {
                                        background: $my-gray-600;
                                    }
        
                                    .custom-task {
                                        .badge {
                                            color: $my-gray-600;
                                        }
                                    }
                                }
        
                                &.started {
                                    color: white;
        
                                    .sg-task-content {
                                        background: $my-blue-500;
                                    }
        
                                    .custom-task {
                                        .badge {
                                            color: $my-blue-500;
                                        }
                                    }
                                }
        
                                &.inprogress {
                                    color: white;
        
                                    .sg-task-content {
                                        background: $my-purple-400;
                                    }
        
                                    .custom-task {
                                        .badge {
                                            color: $my-purple-400;
                                        }
                                    }
                                }
        
                                &.completed {
                                    color: white;
        
                                    .sg-task-content {
                                        background: $my-green-500;
                                    }
        
                                    .custom-task {
                                        .badge {
                                            color: $my-green-500;
                                        }
                                    }
                                }
        
                                &.cancelled, &.failed {
                                    color: white;
        
                                    .sg-task-content {
                                        background: $my-red-500;
                                    }
        
                                    .custom-task {
                                        .badge {
                                            color: $my-red-500;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            /* END LIST section */
        }





        /* DOT */
        .dot {
            position: absolute;
            width: 6px;
            height: 6px;
            top:  35%;
            left: 0px;
            overflow: hidden;
            border-radius: 50%;
            background-color: $my-gray-300;

            &-unallocated, &-draft {
                background-color: $white;
                border: 1px solid $my-gray-400;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 10%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 60%, transparent 60%, transparent);
                    background-size: 10px 10px;
                    pointer-events: none;
                }
            }

            &-assigned {
                background-color: $my-gray-300;
            }

            &-dispatched, &-acknowledged {
                background-color: $my-gray-600;
            }

            &-started {
                background-color: $my-blue-500;
            }

            &-inprogress {
                background-color: $my-purple-400;
            }

            &-completed {
                background-color: $my-green-500;
            }

            &-cancelled, &-failed {
                background-color: $my-red-500;
            }
        }
        /* END DOT */





        /* GOOGLE MAP */
        .gm-style {
            .gm-bundled-control-on-bottom {
                .gmnoprint {
                    > div {
                        background-color: $transparent !important;
                        box-shadow: unset !important;
                
                        > div {
                            background-color: $transparent !important;
                            box-shadow: unset !important;
                            height: 4px !important;
                        }
                
                        .gm-control-active {
                            border-radius: 6px !important;
                            background-color: $white !important;
                            width: 32px !important;
                            height: 32px !important;
                            border: 1px solid $my-gray-300 !important;
                            box-shadow: $my-shadow1 !important;
                
                            img {
                                width: 15px !important;
                                height: 15px !important;
                            }
                        }
                    }
                }
            }

            div[role="dialog"] {
                div {
                    &:first-child {
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 18px;
                        color: #181C32;
            
                        button {
                            width: 28px !important;
                            height: 48px !important;
            
                            span {
                                width: 15px !important;
                                height: 15px !important;
                                margin: 0px !important;
                            }
                        }
                    }
                    &:last-child {
                        font-size: 12px;
                        font-weight: 400 !important;
                        line-height: 18px;
                        color: #6E707E !important;
                        overflow: auto !important;
                        padding-right: 15px !important;
                        padding-bottom: 5px !important;
                    }
                }
            }

            .gm-fullscreen-control {
                border-radius: 6px !important;
                background-color: $white !important;
                width: 32px !important;
                height: 32px !important;
                border: 1px solid $my-gray-300 !important;
                box-shadow: $my-shadow1 !important;
            
                img {
                    width: 15px !important;
                    height: 15px !important;
                }
            }
        }
        .marker-label {
            background-color: $green-500;
            color: $white !important;
            font-weight: bold !important;
            font-size: 11px !important;
            padding: 3px 9px !important;
            border-radius: 20px !important;

            &-orange {
                background-color: #AB6626;
            }
        }
        
        .map-context-menu {
        position: fixed;
        z-index: 1;
        background-color: $white;
        padding: 10px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        
        ul {
            display: inline-flex;
            width: 100%;
            padding: 0px;
            margin: 0px;
            list-style-type: none;
        
            li {
            display: inline-flex;
            padding: 2px 1px;
            color: $secondary;
            cursor: pointer;
        
            svg {
                position: relative;
                top: 3px;
        
                path {
                stroke: $secondary;
                }
            }
        
            &:hover {
                color: $text-muted;
        
                svg {
                path {
                    stroke: $text-muted;
                }
                }
            }
            }
        }
        }
        
        .custom-map-context-menu {
        position: fixed;
        z-index: 1;
        }
        /* END GOOGLE MAP */
    
    }
}