body {
    &[data-page='dashboard'],
    &[data-page='jobs'] {
        background-color: $bg-color;
    }

    &[data-page='payroll-beta-form'] {
        background-color: #EFEFEF;
    }
}

.uppercase-text {
    text-transform: uppercase !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    padding-right: $input-btn-padding-x !important;
    background-image: unset !important;
}

.form-control[readonly] {
    cursor: default !important;
    color: #A1A5B7 !important;
    // border-color: transparent !important;
}

.bg-color {
    background-color: $bg-color !important;
}

.bg-white {
    background-color: white !important;
}

.btn-outline-secondary {
    border: 1px solid $secondary !important;
    &:hover {
        i {
            color: white;
        }
    }
}
.fixed-bottom-pagination {
    position: fixed;
    width: 100%;
    left: 0px;
    bottom: 0px;
}
.fixed-bottom-pagination-70 {
    position: fixed;
    width: 100%;
    left: 0px;
    bottom: 70px;
}

.MuiButton-root {
    font-family: unset !important;
    font-size: unset !important;
    font-weight: unset !important;
    line-height: unset !important;
    letter-spacing: unset !important;
}

.MuiIcon-root {
    width: unset !important;
    height: unset !important;
}

.rc-time-picker-panel {
    z-index: 99999 !important;
}

.custom-block-ui {
    min-height: 95vh;
}
.custom-block-ui-manage {
    min-height: 92vh;
}

.custom-manage-hr {
    background-color: $gray-500;
}

p {
    margin: 6px !important;
}

p+p {
    margin-top: 6px !important;
    margin-bottom: 0px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.mw-90 {
    min-width: 90% !important;
}
.mw-100 {
    min-width: 100% !important;
}
.mw-90-vw {
    @include respond-above('sm') {
        max-width: 90vw;
    }
}
.mw-30-vw {
    @include respond-above('sm') {
        max-width: 30vw;
    }
}

.mw-110 {
    min-width: 110px !important;
    width: 122px;
    @include respond-below('lg') {
        max-width: 100% !important;
        width: 100% !important;
    }
}

.h-auto {
    height: auto !important;
}

.z-index-999999 {
    z-index: 999999;
}
.z-index-1050 {
    z-index: 1050;
}

.marker-pin-number {
    position: relative;
    border-radius: 50%;
    padding: 0px 4px;
    top: -6px;
    left: -1px;
    background-color: $secondary;
    border: 1px solid white;
    font-size: 12px !important;
}

.opacity-80-hover {
    &:hover {
        opacity: 0.80;
    }
}

.label-beta {
    background-color: #9E00FF !important
}

.custom-progress-bar {
    &.billing-primary-progress {
        &:not(.loading){
            .progress-bar {
                &:hover {
                    background-color: #3e76f8;
                }
            }
        }
    }

    &.billing-secondary-progress {
        &:not(.loading){
            .progress-bar {
                &:hover {
                    background-color: #8ae000;
                }
            }
        }
    }
}

.custom-list-check-box {
    padding: 0px;

    &:hover {
        background-color: rgba($primary, 0.05);
    }

    .form-check {
        margin: 0px;
        padding-left: 50px;
        padding-right: 50px;

        label {
            width: 100%;
            padding-top: 15px;
            padding-bottom: 15px;
        }
        input {
            margin-top: 13px;
        }
    }
}

.custom-readonly-bin-waste-field,
.custom-readonly-bin-weight-field {
    &.form-control[readonly] {
        cursor: pointer !important;
        color: $secondary !important;

        &.disabled {
            cursor: no-drop !important;
            color: #A1A5B7 !important;
            border-color: #E4E6EF !important;
        }
    }
}

.rich-text-parse-text {
    white-space: pre-wrap;
    line-height: 10px;
    
    p {
        margin: 0 !important;
        display: block !important;
        line-height: 15px;
    }

    ul, ol {
        margin: 0 !important;

        li {
            margin: 0 !important;
            line-height: 15px;
        }
    }
}

.advanced-filter-svg-inactive {
    g:last-child path {
        fill: #7E8299;
    }
}

.svg-fill-active {
    g {
        fill: $color-active;
    }
}

.audit-trail-page {
    table {
        tbody {
            tr {
                td {
                    b {
                        color: $color-active !important;
                    }
                }
            }
        }
    }
}

.dark-tooltip {
    .tooltip-arrow {
        &::before {
            border-top-color: #484848;
        }
    }

    .tooltip-inner {
        background-color: black;
        color: white;
    }
}

.pagination-fixed {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 20px 20px;
    border: 1px solid #DFDFDF;
}

.as-billing-statuses {
    border-radius: 25px;
    min-height: 30px;
    text-align: center;

    font-size: 12px;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: center;
}

.colored-row-svg {
    & + svg {
        path {
            fill: #dfdfdf !important;
        }
    }

    &:checked {
        & + svg {
            path {
                fill: #dfdfdf !important;
            }
        }
    }
}

.materialtable-center--delete {
    table {
        tbody {
            td {
                &>div {
                    display: unset !important;
                }
            }
        }
    }
}

textarea {
    resize: none !important;
    overflow-y: auto !important;
}


.marker-label {
    background-color: #43936C;
    color: white !important;
    font-weight: bold !important;
    font-size: 11px !important;
    padding: 3px 9px !important;
    border-radius: 20px !important;

    &-orange {
        background-color: #AB6626;
    }
    
    &-started {
        background-color: #185CFF;
    }

    &-inprogress {
        background-color: #D318FF;
    }

    &-completed {
        background-color: #00CB4A;
    }
}

.marker-label-newline {
    background-color: #43936C;
    color: white !important;
    font-weight: bold !important;
    font-size: 11px !important;
    padding: 3px 9px !important;
    border-radius: 10px !important;
    white-space: pre-wrap !important;

    &-orange {
        background-color: #AB6626;
    }
    
    &-started {
        background-color: #185CFF;
    }

    &-inprogress {
        background-color: #D318FF;
    }

    &-completed {
        background-color: #00CB4A;
    }
}

.status {
    &-white {
        &-background {
            background-color: $white !important;
        }
        
        &-color {
            color: $white !important;
        }
    }

    &-draft {
        &-background {
            background-color: $draft !important;
        }
        
        &-color {
            color: $draft !important;
        }
        
        &-border {
            border-color: $draft !important;
        }
    
        &-stroke {
            text-shadow: $draft 1px 0px 0px, $draft 0.540302px 0.841471px 0px, $draft -0.416147px 0.909297px 0px, $draft -0.989992px 0.14112px 0px, $draft -0.653644px -0.756802px 0px, $draft 0.283662px -0.958924px 0px, $draft 0.96017px -0.279415px 0px;
        }
    }

    &-unassigned {
        &-background {
            background-color: $unassigned !important;
        }
        
        &-color {
            color: $unassigned !important;
        }
        
        &-border {
            border-color: $unassigned !important;
        }
    
        &-stroke {
            text-shadow: $unassigned 1px 0px 0px, $unassigned 0.540302px 0.841471px 0px, $unassigned -0.416147px 0.909297px 0px, $unassigned -0.989992px 0.14112px 0px, $unassigned -0.653644px -0.756802px 0px, $unassigned 0.283662px -0.958924px 0px, $unassigned 0.96017px -0.279415px 0px;
        }
    }

    &-assigned {
        &-background {
            background-color: $assigned !important;
        }
        
        &-color {
            color: $assigned !important;
        }
        
        &-border {
            border-color: $assigned !important;
        }
    
        &-stroke {
            text-shadow: $assigned 1px 0px 0px, $assigned 0.540302px 0.841471px 0px, $assigned -0.416147px 0.909297px 0px, $assigned -0.989992px 0.14112px 0px, $assigned -0.653644px -0.756802px 0px, $assigned 0.283662px -0.958924px 0px, $assigned 0.96017px -0.279415px 0px;
        }
    }

    &-dispatched {
        &-background {
            background-color: $dispatched !important;
        }
        
        &-color {
            color: $dispatched !important;
        }
        
        &-border {
            border-color: $dispatched !important;
        }
    
        &-stroke {
            text-shadow: $dispatched 1px 0px 0px, $dispatched 0.540302px 0.841471px 0px, $dispatched -0.416147px 0.909297px 0px, $dispatched -0.989992px 0.14112px 0px, $dispatched -0.653644px -0.756802px 0px, $dispatched 0.283662px -0.958924px 0px, $dispatched 0.96017px -0.279415px 0px;
        }
    }

    &-acknowledged {
        &-background {
            background-color: $acknowledged !important;
        }
        
        &-color {
            color: $acknowledged !important;
        }
        
        &-border {
            border-color: $acknowledged !important;
        }
    
        &-stroke {
            text-shadow: $acknowledged 1px 0px 0px, $acknowledged 0.540302px 0.841471px 0px, $acknowledged -0.416147px 0.909297px 0px, $acknowledged -0.989992px 0.14112px 0px, $acknowledged -0.653644px -0.756802px 0px, $acknowledged 0.283662px -0.958924px 0px, $acknowledged 0.96017px -0.279415px 0px;
        }
    }

    &-started {
        &-background {
            background-color: $started !important;
        }
        
        &-color {
            color: $started !important;
        }
        
        &-border {
            border-color: $started !important;
        }
    
        &-stroke {
            text-shadow: $started 1px 0px 0px, $started 0.540302px 0.841471px 0px, $started -0.416147px 0.909297px 0px, $started -0.989992px 0.14112px 0px, $started -0.653644px -0.756802px 0px, $started 0.283662px -0.958924px 0px, $started 0.96017px -0.279415px 0px;
        }
    }

    &-inprogress {
        &-background {
            background-color: $inprogress !important;
        }
        
        &-color {
            color: $inprogress !important;
        }
        
        &-border {
            border-color: $inprogress !important;
        }
    
        &-stroke {
            text-shadow: $inprogress 1px 0px 0px, $inprogress 0.540302px 0.841471px 0px, $inprogress -0.416147px 0.909297px 0px, $inprogress -0.989992px 0.14112px 0px, $inprogress -0.653644px -0.756802px 0px, $inprogress 0.283662px -0.958924px 0px, $inprogress 0.96017px -0.279415px 0px;
        }
    }

    &-completed {
        &-background {
            background-color: $completed !important;
        }
        
        &-color {
            color: $completed !important;
        }
        
        &-border {
            border-color: $completed !important;
        }
    
        &-stroke {
            text-shadow: $completed 1px 0px 0px, $completed 0.540302px 0.841471px 0px, $completed -0.416147px 0.909297px 0px, $completed -0.989992px 0.14112px 0px, $completed -0.653644px -0.756802px 0px, $completed 0.283662px -0.958924px 0px, $completed 0.96017px -0.279415px 0px;
        }
    }

    &-unsuccessful {
        &-background {
            background-color: $unsuccessful !important;
        }
        
        &-color {
            color: $unsuccessful !important;
        }
        
        &-border {
            border-color: $unsuccessful !important;
        }
    
        &-stroke {
            text-shadow: $unsuccessful 1px 0px 0px, $unsuccessful 0.540302px 0.841471px 0px, $unsuccessful -0.416147px 0.909297px 0px, $unsuccessful -0.989992px 0.14112px 0px, $unsuccessful -0.653644px -0.756802px 0px, $unsuccessful 0.283662px -0.958924px 0px, $unsuccessful 0.96017px -0.279415px 0px;
        }
    }
}